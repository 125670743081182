<template>
  <div class="content block-el">
    <PageHeader :title="$t('sms.short-code.add')">
      <template v-slot:actions>
        <li>
          <a
            v-if="!creating"
            @click="create"
            href="javascript:void(0)"
            class="btn btn-success btn-floating"
          >
            <i
              class="fas fa-save text-white font-size-22 v-align-text-bottom"
            ></i>
          </a>
          <a
            v-else
            href="javascript:void(0)"
            class="btn btn-success btn-floating"
            disabled
          >
            <span class="qt-loader qt-loader-mini qt-loader-right"> </span>
          </a>
        </li>
      </template>
    </PageHeader>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">{{$t('generic-str.menu.general')}}</div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-4">
                  <label for="name">{{$tc('generic-str.name', 1)}}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$tc('generic-str.name', 1)"
                    v-model="form.name"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="name">{{$t('sms.short-code.deliverability')}}</label>
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('generic-str.cut-percentage')"
                    v-model="form.deliverability"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="name">{{$tc('generic-str.lbl-number', 1)}}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$tc('generic-str.lbl-number', 1)"
                    v-model="form.number"
                  />
                </div>
                <div class="form-group col-2">
                  <label for="name">{{$t('generic-str.priority')}}</label>
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('generic-str.priority')"
                    v-model="form.priority"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header">{{$tc('generic-str.menu.providers', 2)}}</div>
            <div class="card-body">
              <div
                v-for="(provider, n) in form.providers"
                :key="n"
                class="form-group row"
              >
                <label class="col-sm-2">
                  <span v-if="provider.carrier == 'all'">Geral</span>
                  <input
                    v-else
                    class="form-control"
                    v-model="provider.carrier"
                  />
                </label>
                <div class="col-sm-8">
                  <select-input
                    v-if="providers.length"
                    label="name"
                    :reduce="(provider) => provider.id"
                    v-model="form.providers[n].id"
                    :options="providers"
                    :clearable="false"
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    v-if="provider.carrier != 'all'"
                    class="btn btn-primary btn-floating"
                    @click="removeProvider(n)"
                  >
                    <i class="fas fa-trash-alt color-white"></i>
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-10 col-sm-2">
                  <button
                    class="btn btn-success btn-floating"
                    @click="addProvider"
                  >
                    <i class="fas fa-plus color-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import json from '@/json/carriers.json';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/admin/sms.service';
import ProviderService from '@/services/admin/provider.service';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'CreateProduct',
  components: {
    PageHeader,
    SelectInput,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: false,
      countries: json,
      types: [
        {
          label: 'SMS',
          value: 'sms',
        },
        {
          label: 'EMAIL',
          value: 'email',
        },
        {
          label: 'WHATSAPP',
          value: 'whatsapp',
        },
        {
          label: 'RCS',
          value: 'rcs',
        },
        {
          label: 'Atendimento',
          value: 'live',
        },
      ],
      providers: [],
      customer: {},
      form: {
        name: '',
        priority: 1,
        deliverability: 100,
        providers: [
          {
            carrier: 'all',
            id: '',
          },
        ],
      },
      creating: false,
      pages: 1,
    };
  },
  created() {
    this.form.type = this.types[0].value;
    this.fetchProviders();
  },
  methods: {
    addProvider() {
      this.form.providers.push({
        carrier: this.$t('sms.infos.filters.field.lbl-carrier'),
        id: '',
      });
    },
    removeProvider(i) {
      this.form.providers.splice(i, 1);
    },
    fetchProviders() {
      this.fetched = false;
      const filter = {};
      filter[this.form.type] = true;
      ProviderService.getProviders(filter).then(
        (providers) => {
          this.providers = providers;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    setProvider(provider) {
      this.form.auth = {};
      this.form.type = provider;
      this.form.name = provider;
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    create() {
      this.creating = true;
      SmsService.createShortCode(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('short-code.added'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (response) => {
            response.errors.forEach((error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.status}`,
                content: error.detail,
                type: 'danger',
              });
            });
          },
        )
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.preloader {
  margin: unset;
  display: block;
}
</style>
